<template>

<!--
    <div class="starter-template">

        <h1>Bootstrap starter template</h1>
        <p class="lead">Use this document as a way to quickly start any new project.<br> All you get is this text and a mostly barebones HTML document.</p>

        <hr>
    </div>
-->

    <div class="container mt-3">

        <div class="row justify-content-center">
            <div class="col-6 d-flex justify-content-center">
                <img src="@/assets/logo.png"/>
            </div>

            <div class="col-6 justify-content-center">
                <div class="row justify-content-center">
                    <img src="@/assets/title.png" class="img-fluid"/>
                    <p class="text-center fs-2 mt-5">Repas à domicile</p>
                    <p class="text-center fs-4 mt-2">Pour personnes âgées, familles, entreprises</p>
                    <p class="fs-4 mt-2 text-center ">
                        <span class="text-center">
                            Sur place ou en livraison
                        </span>
                        <br>
                        <span class="text-center">
                            078 684 18 68
                        </span>
                    </p>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

</script>


<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
