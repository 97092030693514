<template>
    <Home />

    <!--
    <div class="col-12">
        <Home />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import Home from '@/components/bootstrapDashboard/Home.vue'

export default {
    name: 'HomeView',
    components: {
        Home
    }
}
</script>
